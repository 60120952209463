<template>
    <div class="modal-edit-style-type flex items-start">
        <div class="flex-wrap" @click="$emit('close')">
            <i class="material-icons">close</i>
        </div>
        <div class="page-header flex-wrap">
            <div class="title" v-html="$translate(`MODAL_${options.key.toUpperCase()}_TYPE`).replace(/\n/, '<br>')" />
        </div>
        <div class="types">
            <div
                @click="select(item)"
                class="item"
                :class="{ selected: item.$$selected }"
                :key="item.title"
                v-for="item in items"
                v-html="item.title"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalEditStyleType',
    props: ['options'],
    data: () => ({
        items: [],
    }),
    watch: {
        searchValue(newVal) {
            if (!newVal) this.search()
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const gender = this.me.gender === 0 ? 'male' : 'female'
            const types = this.$vbConstants.types[this.options.key]
            this.items = (this.options.key === 'height' ? types[gender] : types).map(o => {
                this.$set(o, '$$selected', o.value === (this.me.style || {})[`${this.options.key}_type`])
                return o
            })
        },
        select(item) {
            this.items.forEach(o => {
                o.$$selected = o.value === item.value
            })
            setTimeout(() => this.$emit('close', item), 200)
        },
    },
}
</script>
